import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../../../../common/components/Title";
import { BASE_URL } from "../../../../api/config";
import { RoomAvailability } from "../../../../../types/availabilities";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Room } from "../../../../../types/context";
import RoomRow from "./RoomRow";
import { useUserContext } from "../../../../context/UserContext";

// Acá sacar las habitaciones y su data, tener tres opciones, un dropdown por año, mes y un datepicker para búsqueda exacta
// Mostrar las habitaciones, la fecha y su disponibilidad en una tabla
// Habilitar un botón de edición para los elementos de la tabla

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Rooms() {
  const { user } = useUserContext();
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const [availabilities, setAvailabilities] = useState<Array<Room>>([]);

  console.log({ year, month });

  useEffect(() => {
    if (user) {
      const query = `?month=${month}&year=${year}`;
      fetch(BASE_URL + "room-availabilities" + query, {
        headers: { "x-auth-token": user.token },
      })
        .then((data) => data.json())
        .then((response) => setAvailabilities(response as Array<Room>));
    }
  }, [month, user, year]);

  const handleMonth = (event: SelectChangeEvent<number>) => {
    setMonth(Number(event.target.value));
  };

  const handleYear = (event: SelectChangeEvent) => {
    setYear(event.target.value);
  };

  const months = [
    {
      value: 1,
      text: "Enero",
    },
    {
      value: 2,
      text: "Febrero",
    },
    {
      value: 3,
      text: "Marzo",
    },
    {
      value: 4,
      text: "Abril",
    },
    {
      value: 5,
      text: "Mayo",
    },
    {
      value: 6,
      text: "Junio",
    },
    {
      value: 7,
      text: "Julio",
    },
    {
      value: 8,
      text: "Agosto",
    },
    {
      value: 9,
      text: "Septiembre",
    },
    {
      value: 10,
      text: "Octubre",
    },
    {
      value: 11,
      text: "Noviembre",
    },
    {
      value: 12,
      text: "Diciembre",
    },
  ];

  return (
    <>
      <Title>Habitaciones</Title>
      <Grid container>
        <Grid item>
          <FormControl sx={{ m: 1, maxWidth: 300 }} size="small">
            <InputLabel id="mes-1">Mes</InputLabel>
            <Select
              labelId="mes-1"
              id="mes-1"
              value={month}
              label="Mes"
              onChange={handleMonth}
            >
              {months &&
                months.map(({ value, text }) => (
                  <MenuItem value={value}>{text}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, maxWidth: 300 }} size="small">
            <InputLabel id="año-1">Año</InputLabel>
            <Select
              labelId="año-1"
              id="año-1"
              value={year}
              label="Año"
              onChange={handleYear}
            >
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Habitación</TableCell>
            <TableCell>Fechas</TableCell>
            <TableCell>Cantidad de habitaciones</TableCell>
            <TableCell>Acción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {availabilities &&
            availabilities.map((row) => <RoomRow room={row} />)}
        </TableBody>
      </Table>
    </>
  );
}
