import { BASE_URL } from "./config";

export const getPrograms = async (token: string) => {
  const apiCall = await fetch(BASE_URL + "programs/all", {
    headers: { "x-auth-token": token },
  });

  const response = await apiCall.json();

  return response;
};

export const createProgram = async (token: string, body: unknown) => {
  const apiCall = await fetch(BASE_URL + "programs", {
    headers: { "x-auth-token": token, "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(body),
  });

  const response = await apiCall.json();

  return response?.data;
};

export const updateProgram = async (
  token: string,
  body: unknown,
  id: string
) => {
  const apiCall = await fetch(`${BASE_URL}programs/${id}`, {
    headers: { "x-auth-token": token, "Content-Type": "application/json" },
    method: "PUT",
    body: JSON.stringify(body),
  });

  const response = await apiCall.json();

  return response?.data;
};

export const updatePhotoProgram = async (
  token: string,
  body: unknown, // Asume que 'file' es un objeto File representando el archivo de imagen
  id: string
) => {
  console.log(body);
  const apiCall = await fetch(`${BASE_URL}programs/photo/${id}`, {
    headers: { "x-auth-token": token, "Content-Type": "application/json" }, // No es necesario especificar "Content-Type" aquí
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(body), // Envía formData directamente
  });

  if (!apiCall.ok) {
    throw new Error(`Error HTTP: ${apiCall.status}`);
  }

  const response = await apiCall.json();
  return response?.data;
};
